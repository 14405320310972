import React, { FunctionComponent } from 'react';

import config from '../../config';
import styles from './IdeasGetYouStarted.module.scss';

interface IdeasCard {
  title?: string;
  description?: string;
  imgSrc?: string;
}

export const IdeasCard: FunctionComponent<IdeasCard> = ({
  title,
  description,

  imgSrc,
}) => {
  return (
    <li className={styles.cardContainer}>
      <div className={styles.startFdrUnitContainer}>
        <div className={styles.fdrContentContainer}>
          <div>
            <h3 className={`jg-h4 ${styles.fdrTitle}`}>{title}</h3>
            <p className={`jg-text-default ${styles.startFdrUnitDescription}`}>
              {description}
            </p>
            <a
              href={config.startFundraisingUrl}
              className={`${styles.startFdrIdeas} ${styles.startFdr}`}
            >
              Start fundraising
            </a>
          </div>
          <div className={styles.startFdrIcon}>
            <img src={imgSrc} alt="" />
          </div>
        </div>
      </div>
    </li>
  );
};
