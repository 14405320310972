import React, { FunctionComponent } from 'react';

import styles from './FundraisingCategories.module.scss';

interface CategorieCardProps {
  href?: string;
  imgSrc?: string;
  title?: string;
}

export const CategorieCard: FunctionComponent<CategorieCardProps> = ({
  imgSrc,
  href,
  title,
}) => {
  return (
    <li className={`${styles.categoryWrapper}`}>
      <div className={styles.categoryIcon}>
        <img src={imgSrc} alt="" />
      </div>
      <a className={`jg-h5 ${styles.categoryText}`} href={href}>
        {title}
      </a>
    </li>
  );
};
