import React, { FunctionComponent } from 'react';

interface Props extends Intl.NumberFormatOptions {
  locale?: string;
  currency?: string;
  value: number;
}

export function getCurrencySymbol(currency: string, locale: string = 'en-GB') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(0)
    .replace(/\d+/, '');
}

export function currencyFormatter({
  value,
  locale = 'en-GB',
  currency = 'GBP',
  ...intlOptions
}: Props) {
  return new Intl.NumberFormat(locale, {
    ...intlOptions,
    style: 'currency',
    currency,
  }).format(value);
}

export const FormattedCurrency: FunctionComponent<Props> = (props) => {
  return <>{currencyFormatter(props)}</>;
};

export const FormattedCurrencyWithoutDecimal: FunctionComponent<Omit<
  Props,
  'minimumFractionDigits' | 'maximumFractionDigits'
>> = (props) => (
  <FormattedCurrency
    {...props}
    minimumFractionDigits={0}
    maximumFractionDigits={0}
  />
);

export default FormattedCurrency;
