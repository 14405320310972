import React, { FunctionComponent } from 'react';

import { CategorieCard } from './CategorieCard';
import animalPawIcon from './assets/animal-paw-icon.svg';
import artPalletIcon from './assets/art-palette-icon.svg';
import config from '../../config';
import ecgIcon from './assets/ecg-icon.svg';
import houseIcon from './assets/house-icon.svg';
import internationalAidIcon from './assets/international-aid-icon.svg';
import mortarBoardIcon from './assets/mortar-board-icon.svg';
import sportIcon from './assets/sport-icon.svg';
import styles from './FundraisingCategories.module.scss';
import wheelchairIcon from './assets/wheelchair-icon.svg';

//TODO: NextJS - Move icons to public folder

export const FundraisingCategories: FunctionComponent = () => {
  return (
    <div className={styles.browseFdrCategory}>
      <div className="jg-grid-container">
        <section className="jg-grid-row">
          <h2 className={`${styles.header} jg-h2`}>
            Browse by fundraising category
          </h2>
          <ul className={styles.categoryContainer}>
            <CategorieCard
              href={`${config.oneSearchUrl}?q=animals%20and%20pets&type=onesearch`}
              imgSrc={animalPawIcon}
              title="Animals and pets"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=art%20and%20culture&type=onesearch`}
              title="Art and culture"
              imgSrc={artPalletIcon}
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=education&type=onesearch`}
              imgSrc={mortarBoardIcon}
              title="Education"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=international%20aid&type=onesearch`}
              imgSrc={internationalAidIcon}
              title="International aid"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=disability&type=onesearch`}
              imgSrc={wheelchairIcon}
              title="Disability"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=local%20community&type=onesearch`}
              imgSrc={houseIcon}
              title="Local community"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=sports&type=onesearch`}
              imgSrc={sportIcon}
              title="Sports"
            />

            <CategorieCard
              href={`${config.oneSearchUrl}?q=health%20and%20medical&type=onesearch`}
              imgSrc={ecgIcon}
              title="Health and medical"
            />
          </ul>
        </section>
      </div>
    </div>
  );
};
