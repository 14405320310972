import React, { FunctionComponent } from 'react';

import config from '../../config';
import styles from './StartFundraisingFooter.module.scss';

export const StartFundraisingFooter: FunctionComponent = () => {
  return (
    <div
      className={styles.startFdrFooter}
      style={{
        backgroundImage: `url(${config.homepageFooterHeroImage})`,
      }}
    >
      <div className={`${styles.gradientOverlay} ${styles.relativeContainer}`}>
        <div className={`jg-grid-container ${styles.lohSpacingL}`}>
          <section className="jg-grid-row jg-grid-flush">
            <h1 className={`${styles.startFdrFooterHeader} jg-h1`}>
              Your story starts here
            </h1>
            <p className={`jg-h3 ${styles.startFdrFooterSubHeader} jg-text-xl`}>
              Find a cause you believe in and make good things happen
            </p>

            <div className="jg-grid-row">
              <div className={styles.ctaButtonContainer}>
                <div className="loh-blue-default-link">
                  <a
                    href={config.startFundraisingUrl}
                    role="button"
                    type="button"
                    className="jg-btn--newPrimary jg-space-mbm loh-blue-default"
                  >
                    Start fundraising
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
