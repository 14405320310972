import * as Sentry from '@sentry/browser';

import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';
import config from './config';
import { onError } from '@apollo/client/link/error';

const link = ApolloLink.from([
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: error => !!error,
    },
  }),
  onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureException(error);

        console.log(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  createHttpLink({
    uri: config.graphqlUrl,
    credentials: 'include',
    fetch,
    fetchOptions: {
      mode: 'cors',
    },
  }),
]);

export const client = new ApolloClient({
  name: 'JG.Homepage',
  link,
  cache: new InMemoryCache({
    //  fragmentMatcher,
  }),
  assumeImmutableResults: true,
});
