import createAuth from '@justgiving/auth/es/utils/createAuth';
import config from '../config';

export const auth = createAuth({
  documentOrReq: document,
  authCookieName: config.authCookieName,
  activeGroupCookieName: config.authActiveGroupsCookieName,
  refreshAccessTokenUrl: config.authRefreshUrl,
  allowGuestLogin: true,
});
