import React, { FunctionComponent } from 'react';

import charityLogo1 from './assets/charity_logo_1.png';
import charityLogo2 from './assets/charity_logo_2.png';
import charityLogo3 from './assets/zero_prostate_cancer.png';
import charityLogo4 from './assets/ymca3_1.png';
import charityLogo5 from './assets/Special_Olympics_logo.png';
import charityLogo6 from './assets/alzheimer_association.png';
import styles from './CharityFooter.module.scss';
import isMobile from '../../utils/isMobile';

interface CharityFooter {
  joinNowLink: string;
  isUS?: boolean;
}

export const CharityFooter: FunctionComponent<CharityFooter> = ({
  joinNowLink,
  isUS = false
}) => {
  return (
    <div className={styles.charityFooter}>
      <div className="jg-grid-container">
        {isUS ?
          <section className="jg-grid-row">
            <p
              className={`${styles.charityFooterHeader} jg-h3 jg-text-xl jg-grid-col-s-12`}
            >
              Over $9 billion raised for non-profits globally
            </p>
            <p
              className={`${styles.subParaText} jg-grid-col-s-12`}
            >
              Raise money and donate to thousands of charities and non-profits
            </p>
            <div
              className={`${styles.charityImageContainer} jg-grid-col-s-12 jg-grid-row`}
            >
              <div className={`${styles.charitySingleImageBox} jg-grid-col-s-12`}>
                <div className={styles.charityImageSet1}>
                  <img src={charityLogo3} alt="" />
                </div>
              </div>
              <div className={`${styles.charitySingleImageBox} jg-grid-col-s-12`}>
                <div className={styles.charityImageSet2Mod}>
                  <img src={isMobile(window) ? charityLogo6 : charityLogo4} alt="" />
                </div>
              </div>
              <div className={`${styles.charitySingleImageBox} jg-grid-col-s-12`}>
                <div className={styles.charityImageSet3}>
                  <img src={isMobile(window) ? charityLogo4 : charityLogo5} alt="" />
                </div>
              </div>
              <div className={`${styles.charitySingleImageBox} jg-grid-col-s-12`}>
                <div className={styles.charityImageSet4}>
                  <img src={isMobile(window) ? charityLogo5 : charityLogo6} alt="" />
                </div>
              </div>
            </div>
          </section>
          :
          <section className="jg-grid-row">
            <p
              className={`${styles.charityFooterSubHeader} jg-h3 jg-text-xl jg-grid-col-s-12`}
            >
              Join{' '}
              <span className={styles.charitiesNumber}>25,788 charities</span>,
              organisations and schools on JustGiving
            </p>
            <div
              className={`${styles.charityImageContainer} jg-grid-col-s-12 jg-grid-row`}
            >
              <div className="jg-grid-col-s-12 jg-grid-col-l-6">
                <div className={styles.charityImageSet1}>
                  <img src={charityLogo1} alt="" />
                </div>
              </div>
              <div className="jg-grid-col-s-12 jg-grid-col-l-6">
                <div className={styles.charityImageSet2}>
                  <img src={charityLogo2} alt="" />
                </div>
              </div>
            </div>
            <div className={`jg-grid-col-s-12 jg-h4 ${styles.charityQuestion}`}>
              <div className="jg-h2">Do you work for a charity?</div>
            </div>
            <div className="loh-blue-default-link">
              <a
                role="button"
                type="button"
                className={`jg-btn--ghost jg-space-mbm ${styles.lohCharityButton} ${styles.lohJoinNowFdrButton}`}
                href={joinNowLink}
              >
                Join now
              </a>
            </div>
          </section>
        }
      </div>
    </div>
  );
};
