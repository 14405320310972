import { Card, CardProps } from './Card';
import React, { FunctionComponent } from 'react';

import GET_PERSISTED_PAGES from '../../queries/GetPersistedPages.gql';
import { GetPersistedPagesQuery } from '../../../types/graphql';
import styles from './HappeningNow.module.scss';
import { useQuery } from '@apollo/client';

interface HappeningNowProps {
  language?: string;
}

export const HappeningNow: FunctionComponent<HappeningNowProps> = () => {
  const { data } = useQuery<GetPersistedPagesQuery>(GET_PERSISTED_PAGES);

  const persistedPages = data?.discoverPersistedPages?.nodes || [];

  if (!persistedPages || persistedPages.length === 0) {
    return null;
  }

  const displayed3persistedPages = persistedPages.slice(0, 3);

  const cards = displayed3persistedPages.map(page => {
    const owner = page.owner
      ? {
        avatar: page.owner.avatar,
        firstName: page.owner.name,
      }
      : undefined;
    const cardProps: CardProps = {
      id: page.title,
      pageUrl: page.url,
      title: page.title,
      owner: {
        avatar: owner?.avatar ?? '',
        firstName: owner?.firstName ?? '',
      },
      coverPhotoUrl: page.cover?.__typename === 'ImageMedia' && page.cover.url,
      amountRaised: page.donationSummary?.totalAmount?.value,
      donationCount: page.donationSummary?.aggregatedDonationsCount ?? page.donationSummary?.donationCount ?? null,
      targetAmount: page.targetWithCurrency?.value,
      pageCurrencyCode: page.donationSummary?.totalAmount?.currencyCode,
    };
    return <Card key={cardProps.id} {...cardProps} />;
  });

  return (
    <div className={styles.cardDiscovery}>
      <div className="jg-grid-container">
        <section className="jg-grid-row">
          <h2 className={`${styles.discoveryCardsHeader} jg-h1`}>
            Happening now
          </h2>
          <ul className={styles.discoveryCardsContainer}>{cards}</ul>
        </section>
      </div>
    </div>
  );
};
