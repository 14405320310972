import React, { FunctionComponent } from 'react';

import { IdeasCard } from './IdeasCard';
import emergencyIcon from './assets/GetStarted_Icons_emergency.svg';
import eventsIcon from './assets/GetStarted_Icons_events.svg';
import helpIcons from './assets/GetStarted_Icons_help.svg';
import occasionIcon from './assets/GetStarted_Icons_occasion.svg';
import styles from './IdeasGetYouStarted.module.scss';

export const IdeasGetYouStarted: FunctionComponent = () => {
  return (
    <div className={styles.ideasToGetStarted}>
      <div className="jg-grid-container">
        <section className="jg-grid-row">
          <h2 className={`${styles.header} jg-h1`}>Ideas to get you started</h2>
          <p className={styles.subHeader}>
            There are lots of ways to make good things happen
          </p>
          <ul className={styles.ideasList}>
            <IdeasCard
              title="Help people in need"
              description=" Provide direct support to an individual, family or community
                    by paying medical expenses or offering financial aid."
              imgSrc={helpIcons}
            />
            <IdeasCard
              title="Take action in an emergency"
              description="Raise funds in response to a natural disaster or
              humanitarian crisis. Make a difference in minutes."
              imgSrc={emergencyIcon}
            />

            <IdeasCard
              title="Take part in a charity event"
              description="Choose from hundreds of official events including marathons,
              bike rides, Dryathlons and bake offs…"
              imgSrc={eventsIcon}
            />

            <IdeasCard
              title="Celebrate an occasion"
              description=" Mark a special event like a birthday, wedding or final exam
                    by asking friends for donations rather than gifts."
              imgSrc={occasionIcon}
            />
          </ul>
        </section>
      </div>
    </div>
  );
};
