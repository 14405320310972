import DefaultAvatar from './assets/avatar_default.svg';
import { FormattedCurrencyWithoutDecimal as FormattedCurrency } from '../FormattedCurrency';
import React from 'react';
import { Totaliser } from '@justgiving/totaliser';
import styles from './Card.module.scss';

export type CardProps = {
  id: string;
  pageUrl: string;
  title: string;
  owner:
    | {
        avatar: string;
        firstName: string;
      }
    | undefined;
  coverPhotoUrl?: string | null;
  amountRaised: number;
  donationCount: number | null;
  targetAmount: number;
  pageCurrencyCode: string;
};

export const Card = (props: CardProps) => {
  let {
    pageUrl,
    title,
    owner,
    coverPhotoUrl,
    amountRaised,
    donationCount,
    targetAmount,
    pageCurrencyCode,
  } = props;

  const imageHandler = (imageType: string, img?: string | null) => {
    switch (imageType) {
      case 'user avatar':
        return img ? `${img}?template=Size136x136Crop` : DefaultAvatar;
      case 'main':
        return img
          ? `${img}?template=FundraisingPageHeroImageM`
          : 'https://images.justgiving.com/image/trainer-no-branding_web.jpg?sourcepath=Stock&trymigrate=true&template=DiscoverCard';
      default:
        throw new Error('The image type provided was not valid');
    }
  };
  const mainImage = imageHandler('main', coverPhotoUrl);
  const userImage = imageHandler('user avatar', owner?.avatar);
  const pageCurrency = pageCurrencyCode || 'GBP';

  const isValidNumber = (value: number | null) => {
    if (value !== 0 && typeof value !== undefined && value !== null) {
      return true;
    }
    return false;
  };

  const ownerExist =
    owner && owner?.firstName.length > 0 && owner?.avatar.length > 0;

  const totalAmount = amountRaised ?? 0;
  const percentageOfTarget = (totalAmount / targetAmount) * 100;
  return (
    <li className={styles.discoveryCardWrapper}>
      <div className={styles.imageMainWrapp}>
        <img className={styles.imageMain} src={mainImage} alt="" />
      </div>
      <div className={styles.cardDetailsWrapper}>
        <a className={styles.titleSpacing} href={pageUrl}>
          <h3 className="jg-h4">{title}</h3>
        </a>
      </div>
      {ownerExist ? (
        <div className={styles.userDetailsContainer}>
          <span>
            <img className={styles.userImage} src={userImage} alt="" />
          </span>
          <p className={styles.userAskDetails}>{owner?.firstName}</p>
        </div>
      ) : null}
      <div className={styles.totaliserDetailsContainer}>
        {isValidNumber(totalAmount) && (
          <Totaliser
            className={styles.jgTotaliserSmall}
            percent={percentageOfTarget}
            size="auto"
            isAnimated
            aria-hidden={false}
          />
        )}
        <span className={styles.totaliserDetails}>
          <span className={styles.amountRaisedTotaliser}>
            <FormattedCurrency
              value={amountRaised / 100}
              currency={pageCurrency}
            />
          </span>

          {isValidNumber(donationCount) && (
            <p className={`jg-display-i ${styles.subTitle}`}>
              raised by
              <br className="br-space" />{' '}
              {donationCount &&
                new Intl.NumberFormat([], {}).format(donationCount)}{' '}
              supporters
            </p>
          )}
        </span>
      </div>
    </li>
  );
};
