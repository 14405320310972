import { CharityFooter } from '../components/CharityFooter/CharityFooter';
import { FundraisingCategories } from '../components/FundraisingCategories/FundraisingCategories';
import { IdeasGetYouStarted } from '../components/IdeasGetYouStarted/IdeasGetYouStarted';
import React from 'react';
import { StartFundraising } from '../components/StartFundraising/StartFundraising';
import { StartFundraisingFooter } from '../components/StartFundraisingFooter/StartFundraisingFooter';
import config from '../config';
import { initHomePage } from './initHomePage';
import { HappeningNow } from '../components/HappeningNow/HappeningNow';
import { Experiments } from '../constants/experiments';
import { useDecision } from '@optimizely/react-sdk';
import { Chrome } from '../components/Chrome/Chrome';
import { Inspiration } from '../components/Inspiration/Inspiration';
import { FundraisingJourney } from '../components/FundraisingJourney/FundraisingJourney';

initHomePage(
  <main>
    <Chrome />
    <StartFundraising />
    <CharityFooter joinNowLink={config.charityJoinNowLinkUS} isUS />
    <HappeningNow />
    <FundraisingJourney />
    <FundraisingCategories />
    <Inspiration />
    <IdeasGetYouStarted />
    <StartFundraisingFooter />
  </main>
);

