// @ts-nocheck
import { FunctionComponent } from 'react';
import { loadClient as loadChrome } from '@justgiving/chrome';

export const Chrome: FunctionComponent = () => {

    loadChrome({
        headerVariant: 'homepageNavGroupedHeader',
        footerVariant: 'homepage',
        searchOpen: true,
    });

    return null;
};