import React, { FunctionComponent } from 'react';

import styles from './FundraisingJourney.module.scss';

interface FundraisingJourneyCardProps {
  title?: string;
  description?: string;
  imgSrc: string;
  count: number;
}

export const FundraisingJourneyCard: FunctionComponent<FundraisingJourneyCardProps> = ({
  title,
  description,
  imgSrc,
  count,
}) => {
  return (
    <li className={styles.card}>
      <div className={styles.ellipse}>
        <div className={styles.ellipseText}>{count}</div>
      </div>
      <img src={imgSrc} alt="" />
      <div className={styles.customContent}>
        <h3 className={`jg-h4 ${styles.subTitle}`}>{title}</h3>
        <p className={styles.customDescription}>{description}</p>
      </div>
    </li>
  );
};
