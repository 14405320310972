import React, { FunctionComponent } from 'react';
import { useOptimizely } from '../../hooks/useOptimizely';
import { Experiments } from '../../constants/experiments';
import config from '../../config';
import styles from './StartFundraising.module.scss';

export const StartFundraising: FunctionComponent = () => {
  const optimizely = useOptimizely();
  const onStartFundraisingClick = () => {
    optimizely?.track(Experiments.TrackingEvents.startFundraisingClick);
  };
  return (
    <>
      {/* some comment */}
      <h1 className="jg-sr-only">JustGiving</h1>
      <div className={styles.startFdrLanding} style={{
        backgroundImage: `url(${config.homepageHeaderBanner
          })`
      }}>
        <div
          className={`${styles.gradientOverlay} ${styles.relativeContainer}`}
        >
          <div className={`jg-grid-container ${styles.lohSpacingL}`}>
            <section className={`jg-grid-row ${styles.contentContainer}`}>
              <h2 className={styles.startFdrHeader}>
                Do something incredible today
              </h2>
              <p className={styles.startFdrSubHeader}>
                Online fundraising for the people and charities you love
              </p>
              <div className="jg-grid-col-s-12 loh-blue-default-link">
                <a
                  href={config.startFundraisingUrl}
                  className={`jg-btn--newPrimary loh-blue-default ${styles.startFundButton}`}
                  onClick={onStartFundraisingClick}
                >
                  Start fundraising
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
