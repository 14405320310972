import React, { FunctionComponent } from 'react';

import styles from './Inspiration.module.scss';

interface InspirationCardProps {
  imgSrc: string;
  href: string;
  hrefText: string;
  onClick?: () => void;
}

export const InspirationCard: FunctionComponent<InspirationCardProps> = ({
  imgSrc,
  href,
  hrefText,
  onClick,
}) => {
  return (
    <li className={styles.card}>
      <img src={imgSrc} alt="" />
      <div className={styles.customContent}>
        <a href={href} className={`${styles.link}`} onClick={onClick}>
          {hrefText}
        </a>
      </div>
    </li>
  );
};
